export class ParticipantContactDetails {
    id: string;
    type: string;
    role: string;
    participantType: string;
    participantRole?: string;
    customer: customer;
    names: names[];
    emails?: emails[];
    addresses?: addresses[];
    phones: phones[];

    constructor() {
        this.id = '';
        this.type = '';
        this.role = '';
        this.participantType = '';
        this.participantRole = '';
        this.customer = new customer();
        this.names = [];
        this.emails = [];
        this.addresses = [];
        this.phones = [];
    }
}

export class phones {
    metaData: metaDataPhone;
    areaCode: string;
    number: string;
    defaultPhone: boolean;

    constructor() {
        this.metaData = new metaDataPhone();
        this.areaCode = '';
        this.number = '';
        this.defaultPhone = false;
    }
}

class metaDataPhone {
    phoneType: string;
    phoneUsage: string;
    phoneStatus: string;
    phoneTypeCode: string;
    phoneUsageCode: string;
    phoneStatusCode: string;

    constructor() {
        this.phoneType = '';
        this.phoneUsage = '';
        this.phoneStatus = '';
        this.phoneTypeCode = '';
        this.phoneUsageCode = '';
        this.phoneStatusCode = '';
    }
}

export class addresses {
    metaData: metaDataAddress
    addressBody: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    defaultAddress: boolean;
    sfPolicyUseIndicator: boolean;
    customerMailPreferenceIndicator: boolean;
    customerResidentialPreferenceIndicator: boolean;
    standardizationResultCode: string;
    partialAddressIndicator: boolean;

    constructor() {
        this.metaData = new metaDataAddress();
        this.addressBody = ''
        this.city = '';
        this.state = '';
        this.postalCode = '';
        this.country = '';
        this.defaultAddress = false;
        this.sfPolicyUseIndicator = false;
        this.customerMailPreferenceIndicator = false;
        this.customerResidentialPreferenceIndicator = false;
        this.standardizationResultCode = '';
        this.partialAddressIndicator = false;
    }
}

class metaDataAddress {
    addressType: string;
    addressUsage: string;
    addressStatus: string;
    addressTypeCode: string;
    addressUsageCode: string;
    addressStatusCode: string;

    constructor() {
        this.addressType = '';
        this.addressUsage = '';
        this.addressStatus = '';
        this.addressTypeCode = '';
        this.addressUsageCode = '';
        this.addressStatusCode = '';
    }
}

export class emails {
    address: string;
    usage: string;
    constructor() {
        this.address = '';
        this.usage = '';
    }
}

export class names {
    first: string;
    last: string;
    customerPreferenceNameIndicator?: boolean;

    constructor() {
        this.first = '';
        this.last = '';
        this.customerPreferenceNameIndicator = false;
    }
}

export class customer {
    id?: string;
    preferredLanguage?: string;
    birthDate: string;
    deceased?: boolean;
    gender?: string;
    minorIndicator?: string;

    constructor() {
        this.id = '';
        this.preferredLanguage = '';
        this.birthDate = '';
        this.deceased = false;
        this.gender = '';
        this.minorIndicator = 'no';
    }
}
