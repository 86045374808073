import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driverlicensenumber'
})
export class DriverlicensenumberPipe implements PipeTransform {

  transform(value: string): string {
    const input = value.toString()
    const len = input.length;
    var mask = ""
    for (let i = 0; i < len - 4; i++) {
      mask += "*"
    }
    return mask + input.slice(len - 4, len)
  }

}
