import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading.service';
import { RouteParamsService } from 'src/app/routing/service/route-params.service';
import { CookieService } from 'src/app/shared/services/helper/cookie.service';
import { CorrelationIdService } from 'src/app/shared/services/helper/correlation-id.service';
import { UrlService } from 'src/app/shared/services/helper/url.service';
import { ClaimNumber } from '../model/claim_number.model';
import { ClientId } from '../model/client_id.model';
import { AuthenticationService } from './authentication.service';
import { VehicleDetailsService } from 'src/app/forms-engine/services/app-services/vehicle-details.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  private claimNumber!: ClaimNumber;
  private clientId!: ClientId;
  private claimsHubURL!: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private correlationIdService: CorrelationIdService,
    private urlService: UrlService,
    private routeParamsService: RouteParamsService,
    private loadingService: LoadingService,
    private authenticationService: AuthenticationService,
    private vehicleDetailsService: VehicleDetailsService
  ) { }

  getClaimNumberObservable(): Observable<ClaimNumber> {
    if (!this.claimNumber) {
      this.loadingService.showLoading();
      const external_claim_id = this.routeParamsService.getClaimId();
      const ssotoken = this.cookieService.getSSOToken();
      const auth = this.cookieService.getOktaJWT();
      const correlation = this.correlationIdService.getCorrelationId();
      let claimnumURL = this.urlService.getServiceUrl('claimNumber');
      claimnumURL = claimnumURL + "?external_claim_id=" + external_claim_id + "";
      const headeroptions = {
        headers: new HttpHeaders({
          'correlation-id': correlation,
          'X_SF_SSO_TOKEN': ssotoken,
          'Authorization': auth,
        })
      };
      return this.http.get(claimnumURL, headeroptions).pipe(
        switchMap((response: any) => {
          this.loadingService.hideLoading();
          this.claimNumber = response as ClaimNumber;
          return of(this.claimNumber);
        }),
        catchError((err) => {
          this.loadingService.hideLoading();
          if (err.status == 401) {
            this.authenticationService.redirectToLogin();
          }
          throw Error(`Error: ` + err)
        })
      );
    } else {
      return of(this.claimNumber)
    }
  }

  getClientIdObservable(): Observable<ClientId> {
    if (!this.clientId) {
      this.loadingService.showLoading();
      const ssotoken = this.cookieService.getSSOToken();
      const auth = this.cookieService.getOktaJWT();
      const correlation = this.correlationIdService.getCorrelationId();
      let customerInfoURL = this.urlService.getServiceUrl('clientId');
      const headeroptions = {
        headers: new HttpHeaders({
          'correlation-id': correlation,
          'X_SF_SSO_TOKEN': ssotoken,
          'Authorization': auth,
        })
      };
      return this.http.get(customerInfoURL, headeroptions).pipe(
        switchMap((response: any) => {
          this.loadingService.hideLoading();
          this.clientId = response as ClientId;
          return of(this.clientId);
        }),
        catchError((err) => {
          this.loadingService.hideLoading();
          if (err.status == 401) {
            this.authenticationService.redirectToLogin();
          }
          throw Error(`Error: ` + err)
        })
      );
    } else {
      return of(this.clientId);
    }
  }

  getClaimsHubURLObservable(): Observable<string> {
    if (!this.claimsHubURL) {
      return this.getClientIdObservable().pipe(switchMap(data => {
        const claim_id = this.routeParamsService.getClaimId();
        const client_id = data.clientId;
        this.claimsHubURL = this.urlService.getServiceUrl('claimsHub');
        this.claimsHubURL = this.claimsHubURL + `claim/${claim_id}/client/${client_id}/role/NI`;
        return of(this.claimsHubURL);
      })
      );
    } else {
      return of(this.claimsHubURL);
    }
  }

  cacheData(): void {
    this.routeParamsService.removeParameters();
    this.vehicleDetailsService.removeVehicleDetails();
  }
}
