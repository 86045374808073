import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs'
import { LoadingService } from 'src/app/core/services/loading.service'
import { ChoiceData } from '../../models/choice-data.model'
import { ParticipantName } from '../../../shared/models/participantName.model'
import { CookieService } from '../../../shared/services/helper/cookie.service'
import { CorrelationIdService } from '../../../shared/services/helper/correlation-id.service'
import { UrlService } from '../../../shared/services/helper/url.service'
import { addresses, emails, names, ParticipantContactDetails, phones } from '../../models/app-models/ParticipantContactDetails.model'
import { ValidatorService } from 'src/app/shared/services/helper/validator.service'
import { AuthenticationService } from 'src/app/external/service/authentication.service'

@Injectable({
    providedIn: 'root'
})
export class ParticipantContactDetailsService {
    private participantContactDetails: ParticipantContactDetails = new ParticipantContactDetails()
    private participantNameText: ParticipantName = new ParticipantName()

    private participantNames: ChoiceData[] = []
    private participantEmails: ChoiceData[] = []
    private participantPhones: ChoiceData[] = []
    private participantAddresses: ChoiceData[] = []

    private participantName: string = ''
    private participantEmail: string = ''
    private participantPhone: string = ''
    private participantAddress: string = ''
    private participantDOB: string = ''
    private participantType: string = ''

    private participantNameObservable$ = new BehaviorSubject(
        this.participantName
    )
    private participantEmailObservable$ = new BehaviorSubject(
        this.participantEmail
    )
    private participantPhoneObservable$ = new BehaviorSubject(
        this.participantPhone
    )
    private participantAddressObservable$ = new BehaviorSubject(
        this.participantAddress
    )
    private participantDOBObservable$ = new BehaviorSubject(
        this.participantDOB
    )
    private participantTypeObservable$ = new BehaviorSubject(
        this.participantType
    )

    constructor(
        private http: HttpClient,
        private urlService: UrlService,
        private correlationIdService: CorrelationIdService,
        private cookieservice: CookieService,
        private loadingService: LoadingService,
        private validatorService: ValidatorService,
        private authenticationService: AuthenticationService
    ) { }

    participantContactDetailsAll(
        external_claim_id: string,
        external_participant_id: string
    ): void {
        if (
            this.validatorService.checkEmpty(this.participantContactDetails.id)
        ) {
            this.loadingService.showLoading()
            const correlation = this.correlationIdService.getCorrelationId()
            const ssotoken = this.cookieservice.getSSOToken()
            const authToken = this.cookieservice.getOktaJWT()
            let participantnameurl =
                this.urlService.getServiceUrl('contactDetails')
            participantnameurl =
                participantnameurl +
                '?external_claim_id=' +
                external_claim_id +
                '&external_participant_id=' +
                external_participant_id +
                ''
            const headeroptions = {
                headers: new HttpHeaders({
                    'correlation-id': correlation,
                    'X_SF_SSO_TOKEN': ssotoken,
                    'Authorization': authToken
                })
            }
            lastValueFrom(this.http.get(participantnameurl, headeroptions))
                .then((response: any): any => {
                    this.loadingService.hideLoading()
                    this.participantContactDetails = response
                    this.formatParticipantContactDetails(response)
                })
                .catch((error: any): any => {
                    this.loadingService.hideLoading()
                    console.log(error)
                    if (error.status == 401) {
                        this.authenticationService.redirectToLogin();
                    } else {
                        this.authenticationService.redirectToErrorPage();
                    }
                })
        }
    }

    formatParticipantContactDetails(res: ParticipantContactDetails): void {
        let resname = res.names.find((name) => name.customerPreferenceNameIndicator)
        let name = resname ? resname : res.names[0];
        this.setName(name?.first, name?.last)
        this.setListofNames(res.names)
        this.participantName = this.formatName(name)
        this.participantNameObservable$.next(this.participantName)

        const date = res.customer.birthDate
        this.participantDOB = this.formatDate(date)
        this.participantDOBObservable$.next(this.participantDOB)

        const type = res.type
        this.participantType = this.formatParticipantType(type)
        this.participantTypeObservable$.next(this.participantType)

        const address = res.addresses?.find((address) => address.defaultAddress)
        this.setListofAddresses(res.addresses)
        this.participantAddress = this.formatAddress(address)
        this.participantAddressObservable$.next(this.participantAddress)

        const phone = res.phones.find((phone) => phone.defaultPhone)
        this.setListofPhones(res.phones)
        this.participantPhone = this.formatPhone(phone)
        this.participantPhoneObservable$.next(this.participantPhone)

        const email = res.emails?.find((email) => email.usage == 'PRSNL')
        this.setListofEmails(res.emails)
        this.participantEmail = this.formatEmail(email)
        this.participantEmailObservable$.next(this.participantEmail)
    }

    participantContactDetailsNameObs(): Observable<string> {
        return this.participantNameObservable$.asObservable()
    }

    participantContactDetailsName(): string {
        return this.participantName
    }

    participantContactDetailsDOBObs(): Observable<string> {
        return this.participantDOBObservable$.asObservable()
    }

    participantContactDetailsDOB(): string {
        return this.participantDOB
    }

    participantContactDetailsTypeObs(): Observable<string> {
        return this.participantTypeObservable$.asObservable()
    }

    participantContactDetailsType(): string {
        return this.participantType
    }

    participantContactDetailsAddressObs(): Observable<string> {
        return this.participantAddressObservable$.asObservable()
    }

    participantContactDetailsAddress(): string {
        return this.participantAddress
    }

    participantContactDetailsPhoneObs(): Observable<string> {
        return this.participantPhoneObservable$.asObservable()
    }

    participantContactDetailsPhone(): string {
        return this.participantPhone
    }

    participantContactDetailsEmailObs(): Observable<string> {
        return this.participantEmailObservable$.asObservable()
    }

    participantContactDetailsEmail(): string {
        return this.participantEmail
    }

    formatName(name: names | undefined): string {
        return name ? name.first + ' ' + name.last : '-'
    }

    formatDate(date: string): string {
        const dateSplite = date.split('-')
        const dateformat = dateSplite[1] + '/' + dateSplite[2] + '/' + dateSplite[0]
        return dateformat
    }

    formatParticipantType(type: string): string {
        if (type === 'NAMINS') {
            return "Named Insured"
        } else if (type === 'CLM') {
            return "Claimant"
        } else if (type === 'DIFFDRVR') {
            return "Different Driver"
        } else if (type === 'INS') {
            return "Insured"
        } else if (type === 'DIFFONR') {
            return "Different Vehicle Owner"
        } else {
            return "Other"
        }
    }

    formatAddress(address: addresses | undefined): string {
        const addressformat = address
            ? address.addressBody +
            ', ' +
            address.city +
            ', ' +
            address.state +
            ' ' +
            address.postalCode.slice(0, 5)
            : '-'
        return addressformat
    }

    formatPhone(phone: phones | undefined): string {
        const phoneData = phone ? phone.areaCode + phone.number : '-'
        const phoneType = phone ? phone.metaData.phoneType : ''
        const phoneformat =
            phoneType +
            ', ' +
            phoneData.substring(0, 3) +
            '-' +
            phoneData.substring(3, 6) +
            '-' +
            phoneData.substring(6, 11)
        return phoneformat
    }

    formatEmail(email: emails | undefined): string {
        return email ? email.address : '-'
    }

    setListofNames(names: names[] | undefined) {
        if (names && names.length > 0) {
            this.participantNames = []
            for (let i = 0; i < names.length; i++) {
                const name = this.formatName(names[i])
                const nameobj: ChoiceData = {
                    id: 'name_' + i.toString(),
                    label: name,
                    value: name,
                    disabled: false,
                    checked: false,
                    groupname: 'contact_name',
                    active: true
                }
                this.participantNames.push(nameobj)
            }
        }
    }

    getListofNames(): ChoiceData[] {
        return this.participantNames
    }

    updateListOfNames(name: ParticipantName): void {
        const naming: names = new names()
        naming.first = name.firstname
        naming.last = name.lastname
        this.setName(naming.first, naming.last)

        const newNameString = this.formatName(naming)
        // The below code can be used to update a list of names and return to the contact info name. This function might not be needed now.
        // const i = this.participantNames.length + 1;
        // const newNameObj = {
        //   id: i.toString(),
        //   label: newNameString,
        //   value: newNameString,
        //   disabled: false,
        //   checked: false,
        //   groupname: 'contact_name'
        // };
        // this.participantNames.push(newNameObj);
        this.participantName = newNameString
        this.participantNameObservable$.next(this.participantName)
    }

    setName(firstname: string | undefined, lastname: string | undefined) {
        this.participantNameText.firstname = firstname ? firstname : '-'
        this.participantNameText.lastname = lastname ? lastname : '-'
    }

    getName(): ParticipantName {
        return this.participantNameText
    }

    setListofAddresses(addresses: addresses[] | undefined) {
        if (addresses && addresses.length > 0) {
            this.participantAddresses = []
            for (let i = 0; i < addresses.length; i++) {
                const address = this.formatAddress(addresses[i])
                const addressobj: ChoiceData = {
                    id: 'address_' + i.toString(),
                    label: address,
                    value: address,
                    disabled: false,
                    checked: addresses[i].defaultAddress,
                    groupname: 'contact_address',
                    active: true
                }
                this.participantAddresses.push(addressobj)
            }
        }
    }

    getListofAddresses(): ChoiceData[] {
        return this.participantAddresses
    }

    updateListofAddresses(address: addresses): void {
        const newAddressString = this.formatAddress(address)
        const i = this.participantAddresses.length + 1
        const newAddressObj = {
            id: 'address_' + i.toString(),
            label: newAddressString,
            value: newAddressString,
            disabled: false,
            checked: false,
            groupname: 'contact_address',
            active: true
        }
        this.participantAddresses.push(newAddressObj)
    }

    updateContactforAddress(value: string): void {
        let indexCheck = this.participantAddresses.findIndex(
            (data) => data.checked
        )
        if (indexCheck > -1) {
            this.participantAddresses[indexCheck].checked = false
        }
        let indexVal = this.participantAddresses.findIndex(
            (data) => data.value === value
        )
        if (indexVal > -1) {
            this.participantAddresses[indexVal].checked = true
        }
        this.participantAddress = value
        this.participantAddressObservable$.next(this.participantAddress)
    }

    setListofPhones(phones: phones[] | undefined) {
        if (phones && phones.length > 0) {
            this.participantPhones = []
            for (let i = 0; i < phones.length; i++) {
                const phone = this.formatPhone(phones[i])
                const phoneobj: ChoiceData = {
                    id: 'phone_' + i.toString(),
                    label: phone,
                    value: phone,
                    disabled: false,
                    checked: phones[i].defaultPhone,
                    groupname: 'contact_phone',
                    active: true
                }
                this.participantPhones.push(phoneobj)
            }
        }
    }

    getListofPhones(): ChoiceData[] {
        return this.participantPhones
    }

    updateListofPhones(phone: phones): void {
        const newPhoneString = this.formatPhone(phone)
        const i = this.participantPhones.length + 1
        const newPhoneObj: ChoiceData = {
            id: 'phone_' + i.toString(),
            label: newPhoneString,
            value: newPhoneString,
            disabled: false,
            checked: false,
            groupname: 'contact_phone',
            active: true
        }
        this.participantPhones.push(newPhoneObj)
    }

    updateContactforPhone(value: string): void {
        let indexCheck = this.participantPhones.findIndex(
            (data) => data.checked
        )
        if (indexCheck > -1) {
            this.participantPhones[indexCheck].checked = false
        }
        let indexVal = this.participantPhones.findIndex(
            (data) => data.value === value
        )
        if (indexVal > -1) {
            this.participantPhones[indexVal].checked = true
        }
        this.participantPhone = value
        this.participantPhoneObservable$.next(this.participantPhone)
    }

    setListofEmails(emails: emails[] | undefined) {
        if (emails && emails.length > 0) {
            this.participantEmails = []
            for (let i = 0; i < emails.length; i++) {
                const email = this.formatEmail(emails[i])
                const emailobj: ChoiceData = {
                    id: 'email_' + i.toString(),
                    label: email,
                    value: email,
                    disabled: false,
                    checked: emails[i].usage == 'PRSNL',
                    groupname: 'contact_email',
                    active: true
                }
                this.participantEmails.push(emailobj)
            }
        }
    }

    getListofEmails(): ChoiceData[] {
        return this.participantEmails
    }

    updateListofEmails(email: emails): void {
        const newEmailString = this.formatEmail(email)
        const i = this.participantEmails.length + 1
        const newEmailobj: ChoiceData = {
            id: 'email_' + i.toString(),
            label: newEmailString,
            value: newEmailString,
            disabled: false,
            checked: false,
            groupname: 'contact_email',
            active: true
        }
        this.participantEmails.push(newEmailobj)
    }

    updateContactforEmail(value: string): void {
        let indexCheck = this.participantEmails.findIndex(
            (data) => data.checked
        )
        if (indexCheck > -1) {
            this.participantEmails[indexCheck].checked = false
        }
        let indexVal = this.participantEmails.findIndex(
            (data) => data.value === value
        )
        if (indexVal > -1) {
            this.participantEmails[indexVal].checked = true
        }
        this.participantEmail = value
        this.participantEmailObservable$.next(this.participantEmail)
    }
}
