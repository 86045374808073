import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MetaData, NgEventBus } from 'ng-event-bus';
import { Observable } from 'rxjs';
import { SfAnalyticsService } from '../logsandtags/sf-analytics.service';
@Component({
  selector: 'app-taglistener',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaglistenerComponent {
    eb = inject(NgEventBus)
    @Input() config = {
      tagPrefix: "Tags",
      target: "**"
    }
   listen() {
     return this.eb.on(`${this.config.tagPrefix}:${this.config.target}`)
   }
}
