import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ConfirmationService } from '../../service/confirmation.service';
import { ClaimNumber } from '../../model/claim_number.model';
import { Observable } from 'rxjs';
import { ClaimnumberPipe } from 'src/app/shared/pipes/claimnumber.pipe';
import { SfAnalyticsService } from 'src/app/shared/services/analytics/logsandtags/sf-analytics.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  providers: [ClaimnumberPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationComponent implements OnInit {
  claimNumber$!: Observable<ClaimNumber>;
  claimsHubURL$!: Observable<string>;
  adobeScreenId = "confirmation"

  //* Services
  confirmationService = inject(ConfirmationService)
  adobe = inject(SfAnalyticsService)

  ngOnInit(): void {
    this.adobe.sendData(this.adobeScreenId);

    this.claimNumber$ = this.confirmationService.getClaimNumberObservable();
    this.claimsHubURL$ = this.confirmationService.getClaimsHubURLObservable();
    this.confirmationService.cacheData();
  }

}
