export class interactionSetEventRequest {
    form_instance_id: string
    sfcustId: string
    eiid: string
    sfuid?: string
    sfcustrole: string

    constructor() {
        this.form_instance_id = ''
        this.sfcustId = ''
        this.eiid = ''
        this.sfcustrole = ''
    }
}