import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Auth } from '../model/auth.model'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { lastValueFrom } from 'rxjs'
import { UrlService } from 'src/app/shared/services/helper/url.service'
import { CookieService } from 'src/app/shared/services/helper/cookie.service'
import { CorrelationIdService } from 'src/app/shared/services/helper/correlation-id.service'

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private authentication: Auth = { loa: 0 }

    constructor(
        private http: HttpClient,
        private router: Router,
        private urlService: UrlService,
        private cookieService: CookieService,
        private correlationIdService: CorrelationIdService
    ) { }

    public isAuthenticated(): Promise<boolean> {
        const canAccess: Promise<boolean> = this.getAuthInfo()
            .then((response) => {
                if (response.loa === 4 || response.loa === 2) {
                    return true
                } else {
                    this.redirectToLogin()
                    return false
                }
            })
            .catch((error: any) => {
                console.log(error)
                if (error.status === 401) {
                    this.redirectToLogin()
                } else {
                    this.redirectToErrorPage()
                }
                return false
            })
        return canAccess
    }

    public redirectToLogin(): void {
        const currentUrl = window.location.href
        const returnUrl = encodeURIComponent(currentUrl)
        const redirectUrl = this.urlService.getServiceUrl('proofing')
        const url = redirectUrl + '/login-interceptor/login?goto=' + returnUrl
        window.open(url, '_self')
    }

    public redirectToErrorPage(): void {
        this.router.navigate(['/technical-error'])
    }

    public redirectToConfirmationPage(): void {
        this.router.navigate(['/confirmation'])
    }

    getAuthInfo(): Promise<Auth> {
        //const sso = this.cookieService.getSSOToken()
        const auth = this.cookieService.getOktaJWT()
        const correlation = this.correlationIdService.getCorrelationId()
        const authurl = this.urlService.getServiceUrl('authdetails')
        if (auth !== '') { //sso !== '' ||
            const headeroptions = {
                headers: new HttpHeaders({
                    //TODO pass auth token
                    // if we have bearer token pick it else get sso token
                    'X_SF_SSO_TOKEN': '',
                    'Authorization': auth,
                    'correlation-id': correlation
                })
            }
            const response = lastValueFrom(this.http.get(authurl, headeroptions))
                .then((response: any): Promise<any> => {
                    this.setAuthState(response)
                    return Promise.resolve(response)
                })
                .catch((error: any): Promise<any> => {
                    return Promise.reject(error)
                })
            return response
        }
        return Promise.reject({ status: 401 })
    }

    public setAuthState(response: Auth): void {
        this.authentication.loa = response.loa
    }

    public getAuthState(): number {
        return this.authentication.loa
    }

}
