import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhonenumberPipe implements PipeTransform {

  transform(value: string): string {
    const reg = /([0-9]{3})([0-9]{3})([0-9]{4})/;
    const pattern = '$1-$2-$3';
    let val = value.replace(/[\$()-\s]/g, '');
    val = val.replace(reg, pattern);
    return val;
  }

}
