import { Injectable } from '@angular/core';
import { CookieService } from 'src/app/shared/services/helper/cookie.service'
import { UrlService } from '../helper/url.service';
import { interactionSetEventRequest } from './interaction.model';
import { ParticipantContactDetailsService } from 'src/app/forms-engine/services/app-services/participant-contact-details.service';
import { RouteParamsService } from 'src/app/routing/service/route-params.service';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading.service';
import { AuthenticationService } from 'src/app/external/service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class InteractionService {
  interaction: interactionSetEventRequest = new interactionSetEventRequest()

  constructor(
    private cookieservice: CookieService,
    private urlService: UrlService,
    private participantContactDetailsService: ParticipantContactDetailsService,
    private routeParamsService: RouteParamsService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private authenticationService: AuthenticationService
  ) { }

  setInteractionData() {
    this.loadingService.showLoading();
    setTimeout(() => {
      this.initializeInteractionData();
      if (this.interaction.eiid === '') {
        setTimeout(() => {
          this.initializeInteractionData();
        }, 4500);
      }
      this.loadingService.hideLoading();
    }, 4500);
  }

  initializeInteractionData() {
    const claim_id = this.routeParamsService.getClaimId();
    const participant_id = this.routeParamsService.getParticipantId();
    this.updateParticipantDetailsRole(claim_id, participant_id);
    this.interaction.sfcustId = this.cookieservice.getCookieValue('SFcustId=')
    this.interaction.eiid = this.cookieservice.getCookieValue('enterprise_interaction_identifier=')
    this.interaction.sfuid = this.cookieservice.getCookieValue('sfuid=')
    this.interaction.form_instance_id = claim_id + '-' + participant_id
  }

  getInteractionData() {
    return this.interaction
  }

  updateInteractionData() {
    let interactionURL = this.urlService.getServiceUrl('interactions_url');
    interactionURL = interactionURL + '?interaction_id=set'
    this.http.post(interactionURL, this.interaction).pipe(take(1)).subscribe(data => {
      console.log('set interaction data: ' + data)
    })
  }

  triggerStartInteraction() {
    let interactionURL = this.urlService.getServiceUrl('interactions_url');
    interactionURL = interactionURL + '?interaction_id=start';
    const claim_id = this.routeParamsService.getClaimId();
    const participant_id = this.routeParamsService.getParticipantId();
    const request_body = {
      clm_id: claim_id,
      participant_id: participant_id
    }
    this.http.post(interactionURL, request_body).pipe(take(1)).subscribe(data => {
      console.log('start interaction: ' + data)
    })
  }

  updateParticipantDetailsRole(cid: string, pid: string) {
    const loa = this.authenticationService.getAuthState()
    if (loa === 4) {
      const type = this.participantContactDetailsService.participantContactDetailsType()
      if (type === "") {
        this.participantContactDetailsService.participantContactDetailsAll(cid, pid)
        this.participantContactDetailsService.participantContactDetailsTypeObs().subscribe(data => {
          this.interaction.sfcustrole = data;
        })
      }
      else {
        this.interaction.sfcustrole = type
      }
    }
  }
}
