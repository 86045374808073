import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MetaData } from 'ng-event-bus';
import { SfAnalyticsService } from '../logsandtags/sf-analytics.service';
import { TaglistenerComponent } from '../taglistener/taglistener.component';

@Component({
  selector: 'app-analytics-tag-listener',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyticsTagListenerComponent extends TaglistenerComponent implements OnInit {

    // Listen for all Analytics events
    override config = {
        tagPrefix: "FM:Tags:Analytics",
        target: "**"
    }

    sfa = inject(SfAnalyticsService)
    ngOnInit(): void {
        this.listen().subscribe((meta: MetaData)=>{
            const screenId = meta.data.screenId
            const claimId = meta.data.claimId
            const participantId = meta.data.clientID

            this.sfa.setValues({
                claimId: claimId ? claimId : "not set",
                clientID: participantId ? participantId : "not set"
            })
            this.sfa.sendData(screenId ? screenId : "not set")

        })

    }

}
