import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { CorrelationIdService } from 'src/app/shared/services/helper/correlation-id.service';
import { UrlService } from 'src/app/shared/services/helper/url.service';
import { ValidatorService } from 'src/app/shared/services/helper/validator.service';
import { ParticipantName } from '../../models/participantName.model';
import { CookieService } from '../helper/cookie.service';
import { AuthenticationService } from 'src/app/external/service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ParticipantNameService {

  participantname: ParticipantName = new ParticipantName();

  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private correlationIdService: CorrelationIdService,
    private cookieservice: CookieService,
    private validatorService: ValidatorService,
    private authenticationService: AuthenticationService
  ) { }

  participantName(external_claim_id: string, external_participant_id: string): Observable<ParticipantName> {
    if (this.validatorService.checkEmpty(this.participantname.firstname) || this.validatorService.checkEmpty(this.participantname.lastname)) {
      const correlation = this.correlationIdService.getCorrelationId();
      const ssotoken = this.cookieservice.getSSOToken();
      const auth = this.cookieservice.getOktaJWT();
      let participantnameurl = this.urlService.getServiceUrl('participantsname');
      participantnameurl = participantnameurl + "?external_claim_id=" + external_claim_id + "&external_participant_id=" + external_participant_id + "";
      const headeroptions = {
        headers: new HttpHeaders({
          'correlation-id': correlation,
          'X_SF_SSO_TOKEN': ssotoken,
          'Authorization': auth,
        })
      };
      return this.http.get(participantnameurl, headeroptions).pipe(
        switchMap((response: any) => {
          this.participantname.firstname = response.firstname;
          this.participantname.lastname = response.lastname;
          return of(this.participantname);
        }), catchError((err: any) => {
          if (err.status == 401) {
            this.authenticationService.redirectToLogin();
          } else {
            this.authenticationService.redirectToErrorPage();
          }
          throw Error(err.message)
        })
      )
    } else {
      return of(this.participantname);
    }
  }
}
