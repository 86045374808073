import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'policyNumber'
})
export class PolicynumberPipe implements PipeTransform {

  transform(value: string): string {
    const input = value.toUpperCase().replace(/[^0-9A-Z]/g, '');
    const len = input.length;
    let modifiedinput = value;
    const minvar = 'min';
    const maxvar = 'max';
    const regvar = 'reg';
    const patternvar = 'pattern';
    const defs = [
      { min: 3, max: 7, reg: /([0-9A-Z]{3})([0-9A-Z]{1,4})/, pattern: '$1 $2' },
      { min: 7, max: 10, reg: /([0-9A-Z]{3})([0-9A-Z]{4})([0-9A-Z]{1,3})/, pattern: '$1 $2-$3' },
      { min: 10, max: 13, reg: /([0-9A-Z]{3})([0-9A-Z]{4})([0-9A-Z]{3})([0-9A-Z]{1,3})/, pattern: '$1 $2-$3-$4' },
      { min: 13, max: 15, reg: /([0-9A-Z]{3})([0-9A-Z]{4})([0-9A-Z]{3})([0-9A-Z]{2})([0-9A-Z]{3})/, pattern: '$1 $2-$3-$4-$5' },
      { min: 13, max: 16, reg: /([0-9A-Z]{3})([0-9A-Z]{4})([0-9A-Z]{3})([0-9A-Z]{3})([0-9A-Z]{3})/, pattern: '$1 $2-$3-$4-$5' },
    ];
    defs.forEach((def: any) => {
      if (len > def[minvar] && len <= def[maxvar]) {
        modifiedinput = input.replace(def[regvar], def[patternvar]);
      }
    });
    return modifiedinput;
  }

}
